@import "@hagerty/design-system/src/utilities/functions/deep-map-check";
@import "@hagerty/design-system/src/utilities/functions/deep-map-get";
@import "@hagerty/design-system/src/utilities/functions/get";
@import "@hagerty/design-system/src/utilities/functions/pem";
@import "@hagerty/design-system/src/utilities/functions/rem";
@import "@hagerty/design-system/src/utilities/functions/str-explode";
@import "@hagerty/design-system/src/_variables";
@import "@hagerty/design-system/src/dependencies/_font-properties";
@import "@hagerty/design-system/src/dependencies/_sizing";
@import "@hagerty/design-system/src/dependencies/_forms";
@import "@hagerty/design-system/src/dependencies/_links.scss";
@import "@hagerty/design-system/src/components/button";
@import "@hagerty/design-system/src/components/modal";
@import "@hagerty/design-system/src/components/form";
@import "@hagerty/design-system/src/components/alerts";
@import "@hagerty/design-system/src/components/submenu";
@import "@hagerty/design-system/src/utilities/text/_text";

$color-skeleton-on-white-background: #f5f6f8;

@mixin skeleton {
  border-radius: $border-radius-medium;
  background-color: $color-skeleton-on-white-background;
}

.hidden {
  display: none;
}

.tryAgain {
  font-weight: normal;
}

.disclaimer {
  padding: 16px 0;

  p {
    font-size: 0.875rem;
    line-height: 1.42;
    color: #484c51;
  }
}
